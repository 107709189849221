const errorMessages: Record<string, any> = {
  authentication_failed: [
    {
      condition: (error: any) =>
        error?.response?.data?.error_message.includes("User") &&
        error?.response?.data?.error_message.includes("not exists"),
      message: (error: any) => {
        const email =
          error?.response?.data?.error_message.match(/: (.*?) is/)?.[1] || "";
        return `User with email ${email} does not exist. Please sign up.`;
      },
    },
  ],
  validation_error: [
    {
      condition: (error: any) =>
        error?.response?.data?.error_message.includes(
          "Invalid or expired code"
        ),
      message: () =>
        "Incorrect or expired code. Re-enter the code from your email, or request a new one.",
    },
    {
      condition: (error: any) =>
        error?.response?.data?.error_message.includes(
          "provision_public_key - This field may not be blank"
        ),
      message: () =>
        "Public Key is required. Please enter your Public Key to proceed with the action.",
    },
    {
      condition: (error: any) =>
        error?.response?.data?.error_message.includes(
          "domain is not available"
        ),
      message: () =>
        "The domain you entered is already taken. Please choose another domain and try again.",
    },
  ],
};

export const parseError = (error: any) => {
  const codeErrorMessages =
    errorMessages[error?.response?.data?.error_code as string];
  const errorMessage = codeErrorMessages.find(({ condition }: any) =>
    condition(error)
  );
  if (errorMessage) {
    return errorMessage.message(error);
  } else {
    return error?.response?.data?.error_message
      ? error?.response?.data?.error_message
      : error.message;
  }
};
