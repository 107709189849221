const { hostname } = window.location;

const MAPPING = {
  ["localhost"]: {
    API_URL: "https://vna-crm.test.erty.sh/api/v1",
    GOOGLE_CLIENT_ID:
      "168698886450-20v7af4b7btvb87t59l4jtfotgv0hjo7.apps.googleusercontent.com",
  },
  ["ca.test.erty.sh"]: {
    API_URL: "https://ca.test.erty.sh/api/v1",
    GOOGLE_CLIENT_ID:
      "792788386532-dfdelu1u61ap3o5paicr5u88djs19ekv.apps.googleusercontent.com",
  },
  ["dashboard.dev.erty.sh"]: {
    API_URL: "https://crypto-treasury-crm.dev.erty.sh/api/v1",
    GOOGLE_CLIENT_ID:
      "865732353075-btviuh3gi61d85dleusnhg5eqkm43dj1.apps.googleusercontent.com",
  },
  ["vna-dashboard.test.erty.sh"]: {
    API_URL: "https://vna-crm.test.erty.sh/api/v1",
    GOOGLE_CLIENT_ID:
      "168698886450-20v7af4b7btvb87t59l4jtfotgv0hjo7.apps.googleusercontent.com",
  },
  ["dms-dashboard.test.erty.sh"]: {
    API_URL: "https://dms-crm.test.erty.sh/api/v1",
    GOOGLE_CLIENT_ID:
      "363540402393-9ntisjnkjlqebj2t0ducvoar4d3r776j.apps.googleusercontent.com",
  },
  ["ca.stage.exan.tech"]: {
    API_URL: "https://ca.stage.exan.tech/api/v1",
    GOOGLE_CLIENT_ID:
      "168698886450-hba2pp06pu48jp2gr99m77e4f3ql0q90.apps.googleusercontent.com",
  },
  ["dashboard.demo.exan.tech"]: {
    API_URL: "https://crm.demo.exan.tech/api/v1",
    GOOGLE_CLIENT_ID:
      "168698886450-20v7af4b7btvb87t59l4jtfotgv0hjo7.apps.googleusercontent.com",
  },
  ["ca.multik.io"]: {
    API_URL: "https://ca.multik.io/api/v1",
    GOOGLE_CLIENT_ID:
      "168698886450-hba2pp06pu48jp2gr99m77e4f3ql0q90.apps.googleusercontent.com",
  },
  ["ca.dev.erty.sh"]: {
    API_URL: "https://ca.dev.erty.sh/api/v1",
    GOOGLE_CLIENT_ID:
      "168698886450-20v7af4b7btvb87t59l4jtfotgv0hjo7.apps.googleusercontent.com",
  },
};

export const {
  API_URL,
  GOOGLE_CLIENT_ID,
  // @ts-ignore
} = MAPPING[hostname] || {};
