import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";
import Button from "../Button";
import { useAuth } from "@/hooks/useAuth";

const creatingSteps = [
  {
    content: `Open the Provisioning app and select "I don't have an account".`,
  },
  {
    content: `Choose “Create account”.`,
  },
  {
    content: (domain: string) => `Enter your domain <u><b>${domain}</b></u>`,
  },
  {
    content: `Set and confirm your password.`,
  },
  {
    content: `On the next screen, you will see a passphrase. 
Please write it down and store it in a secure location.
</br>
</br>
<b>Do not share this passphrase with anyone under any circumstances! 
Losing the passphrase could result in permanent loss of access to your environment.</b>`,
  },
  {
    content: `Confirm passphrase on the next screen`,
  },
  {
    content: `On the next screen you will see the public key. 
You will need it in the next steps of the instructions.`,
  },
];

export default function ViewInstructionsModal() {
  const [open, setOpen] = React.useState(false);
  const { user } = useAuth();

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button type="outlined" text="View instructions" />
        {createPortal(
          <AnimatePresence mode="popLayout">
            {open && (
              <motion.div
                className="overflow-hidden w-full h-full absolute top-0 left-0"
                exit={{
                  backdropFilter: "blur(5px) opacity(0)",
                }}
                initial={{
                  backdropFilter: "blur(5px) opacity(0)",
                }}
                animate={{
                  backdropFilter: "blur(5px) opacity(1)",
                }}
              ></motion.div>
            )}
          </AnimatePresence>,
          document.getElementById("blur") as HTMLElement
        )}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
          <div className="p-5 max-w-[480px] bg-base rounded-md border border-border text-text">
            <div>
              <div className="flex justify-between items-center mb-5">
                <div className="text-[20px] font-bold">
                  Creating Provisioning app account
                </div>
                <div className="cursor-pointer" onClick={() => setOpen(!open)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5L5 15"
                      stroke="#475673"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 5L15 15"
                      stroke="#475673"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {creatingSteps.map(({ content }, idx) => (
                <div>
                  <div className="text-[11px] text-object-secondary uppercase mb-[10px]">
                    Step {idx + 1}
                  </div>
                  <div
                    className="text-text text-[14px] mb-5"
                    dangerouslySetInnerHTML={{
                      __html:
                        typeof content === "string"
                          ? content
                          : content(user?.provision_domain),
                    }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
